.login-modal .modal-content {
    border-radius: 0 !important;
}

.login-modal-body {
    padding: 0 !important;
}

.login-modal-body-inner {
    background-color: #E4E4E4;
    min-height: 672px;
}

.login-bg-white {
    background-color: #fff;
}

.login-modal-logo {
    display: block;
    padding: 24px 0;
    text-align: center;

}

.login-modal-btn {
    width: 100%;
    font-family:"TT Hoves" !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 28px !important;
    border-radius: 0 !important;
    border: none !important;
}

.login-modal-btn:first-of-type {
    background-color: #3975EA !important;
    color: #FFFFFF !important;
}

.login-modal-btn:last-of-type {
    background-color: #DCDCDC !important;
    color: #363636 !important;
}

.login-modal-form {
    background-color: #F2F2F2;
}

.login-modal-text {
    font-family: "TT Hoves";
    font-size: 15px;
    font-weight: 400;
    line-height: 17.67px;
    text-align: left;
    color: #8C8C8C;
}

.login-modal-input {
    display: block;
    border: 0.5px solid #D6D6D6;
    border-radius: 0;
    padding: 12px;
    width: 100%;
    margin-bottom: 4px;
}

.login-modal-input,
.login-modal-input::placeholder {
    color: #8C8C8C;
    font-family: "TT Hoves";
    font-size: 18px;
    font-weight: 400;
    line-height: 21.2px;
}

.login-modal-form-btn {
    width: 100%;
    background: #FF4330 !important;
    border-radius: 8px;
    border: none !important;
}

.login-modal-footer-text {
    font-family: "TT Hoves";
    font-size: 15px;
    font-weight: 400;
    line-height: 17.67px;
    color: #8C8C8C;
}

.login-modal-footer-text span {
    font-family: "TT Hoves";
    font-size: 15px;
    font-weight: 700;
    line-height: 17.67px;
    color: #FF4330;
    cursor: pointer;
}

.control-error {
    font-family: "TT Hoves";
    font-size: 12px;
    color: #FF4330;
    text-align: left;
}

.register-eye-icon {
    position: absolute;
    top: 30%;
    right: 0;
    cursor: pointer;
    color: #7b7b7b;
}

@media screen and (max-width: 600px) {
    .login-sm-none {
        display: none;
    }
}