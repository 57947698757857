.dropdown-filter {
    background-color: #fff !important;
    border-radius: 8px !important;
    box-shadow: 0px 4px 10px 0px #00000026 !important;
    font-family: "TT Hoves" !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 21px !important;
    color: #000 !important;
    border: none !important;
    padding: 10px 36px !important;
}

.dropdown-filter::after {
    display: none !important;
}

.dropdown-menu-filter {
    border-radius: 10px !important;
    box-shadow: 0px 4px 10px 0px #00000026;
    border: none !important;
    padding: 32px !important;
    font-family: "TT Hoves";
    font-size: 18px !important;
    font-weight: 500;
    line-height: 21px;
}

.dropdown-menu-span {
    width: 18px !important;
    height: 18px !important;
    border: 1px solid #FF4330;
    border-radius: 100%;
    margin-right: 8px;
    margin-bottom: 0;
}

.dropdown-item:hover .dropdown-menu-span {
    background-color: #FF4330;
    transition: 0.1s linear;
}