.instructor-name {
    font-family: "Arimo";
    font-size: 29px;
    font-weight: 500;
    line-height: 39px;
    color: #161616;
}

.detail-badge {
    background-color: #00B2BD;
    padding: 2px 6px;
    border-radius: 2px;
    color: #fff;
    font-family: "Arimo";
    font-size: 11px;
    font-weight: 500;
    line-height: 18px;
}

.detail-span {
    font-family: "Arimo";
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #505050;
}

.instructor-content-text {
    font-family: "Arimo";
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    color: #161616;
    margin-top: 20px;
    width: 60%;
}

.bg-gray {
    background-color: #F2F2F2;
}

.bg-white {
    background-color: #fff;
}

.user-courses-header {
    font-family: "TT Hoves";
    font-size: 27px;
    font-weight: 700;
    line-height: 32px;
    color: #232323;
}

.profil-img {
    background-color: #D9D9D9;
    width: 170px;
    height: 170px;
}