.headerTop-header {
    font-family: "TT Hoves Bold";
    font-size: 37px;
    font-weight: 700;
    line-height: 44px;
}

.headerTop-logo {
    width: 50%;
    display: block;
}

.headerTop-header span {
    color: #C13230;
}

.healthCare-wp {
    font-family: "TT Hoves" !important;
    font-size: 30px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    color: #fff !important;
    box-shadow: 0px 5px 20px 0px #00000026 !important;
    background-color: #69E483 !important;
    border-radius: 8px !important;
    border: none !important;
    padding: 16px 40px !important;
    float: right;
    margin-bottom: 14px;
}

.headerTop-icon {
    margin-right: 6px;
}

.spacer-top {
    display: none;
}

@media screen and (max-width: 600px) {
    .headerTop-logo {
        width: 84%;
        margin-left: auto;
    }

    .headerTop-header {
        font-size: 15px;
        line-height: 16px;
        text-align: center;
    }

    .healthCare-wp {
        font-size: 10px !important;
        line-height: 12px !important;
        padding: 8px 16px !important;
        float: none;
        display: block!important;
        margin: auto;
    }
    .headerTop-icon {
        width: 12px;
        margin-right: 4px!important;
    }

    .headerTop-wrapper {
        position: fixed;
        z-index: 20;
        background-color: white;
    }

    .spacer-top {
        display: block;
        width: 100%;
        height: 64px;
    }
}