.instructor-header {
    font-family: "TT Hoves";
    font-size: 27px;
    font-weight: 700;
    line-height: 32px;
    color: #232323;
}

.instructor-name {
    font-family: "TT Hoves";
    font-size: 29px;
    font-weight: 600;
    line-height: 39px;
    color: #161616;
}

.instructor-badge {
    padding: 2px 6px;
    border-radius: 2px;
    color: #fff;
    font-family: "TT Hoves";
    font-size: 11px;
    font-weight: 500;
    line-height: 18px;
}

.instructor-badge-danger {
    background-color: #FF4330;
}

.instructor-badge-info {
    background-color: #00B2BD;
}

.instructor-text {
    font-family: "TT Hoves";
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    color: #747474;
}

.about-instructor-img {
    width: 180px;
    height: 180px;
}