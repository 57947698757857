.header-bg {
    background-color: #FF4330;
}

.navbar-brand {
    margin-right: 48px!important;
}

.nav-link {
    font-family: "TT Hoves" !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 21px !important;
    color: #fff !important;
    margin-right: 16px !important;
}

.nav-link.active {
    font-weight: 700 !important;
    text-decoration: underline !important;
}

.input-wrapper {
    background-color: #C13230;
    border-radius: 8px;
    font-family: "TT Hoves";
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;
    color: #fff !important;
    position: relative;
    padding: 4px 4px 4px 32px;
    margin: 0 40px 0 20px;
    width: 244px;
}

.search-icon {
    position: absolute;
    top: 30%;
    left: 10px;
}

.search-input {
    background-color: transparent !important;
    border: none !important;
    color: #fff !important;
}

.search-input::placeholder {
    color: #fff !important;
}

.search-input:focus {
    background-color: transparent !important;
    box-shadow: none !important;
}

.nav-login,
.nav-register {
    min-width: 154px !important;
    border-radius: 8px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    padding: 10px 8px !important;
}

.nav-login {
    border: 1.5px solid #fff !important;
    background-color: transparent !important;
    color: #fff !important;
    margin-right: 20px;
}

.nav-register {
    border: 1.5px solid #fff !important;
    background-color: #fff !important;
    color: #000 !important;
}

.footer-title {
    font-family: "TT Hoves Medium";
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
}

.footer-arrowIcon {
    color: #FF4330;
    margin-left: 20px;
}

.footer-listItem {
    font-family: "TT Hoves";
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
}

.courses-dropdown .dropdown-menu {
    width: 100% !important;
    border: none !important;
    border-radius: 0 !important;
    padding: 40px 0 160px;
}

.profil-dropdown #navbarScrollingDropdown {
    border-radius: 8px;
    padding: 10px 28px;
    background-color: #fff;
    font-family: "TT Hoves";
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    color: #000 !important;
}

.profil-dropdown.show {
    background-color: #fff;
    border-radius: 8px 8px 0 0;
}

.profil-dropdown .dropdown-menu {
    border: none;
    border-radius: 0;
    box-shadow: 0px 24px 30px 0px #00000040;
    top: 56px !important;
    left: -81.5px !important;
    padding: 20px;
}

.dropdown-item:hover {
    color: #FF4330 !important;
    background-color: transparent !important;
}

.searchArrow {
    color: #fff;
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 12px;
    cursor: pointer;
}

.nav-courses-flex-left {
    padding-right: 48px;
    margin-right: 48px;
}

.nav-courses-flex-right {
    padding-right: 48px;
}

.navbar-toggler {
    border-color: transparent !important;
    font-size: 24px !important;
    padding: 0!important;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

.nav-mobile-reg {
    display: none;
}

.spacer {
    display: none;
}

.navbar {
    position: static !important;
}

.header-bg {
    position: relative;
}

@media screen and (max-width: 600px) {
    .dropdown-item {
        color: #fff !important;
    }

    .navbar-brand {
        margin-right: 14px!important;
    }

    .courses-dropdown .dropdown-menu {
        background-color: transparent;
        color: #fff;
    }

    .search-icon {
        left: 20px;
    }

    .navbar .container-fluid {
        flex-wrap: nowrap !important;
        padding: 0;
    }

    .header-bg {
        position: fixed;
        z-index: 20;
        width: 100%;
    }

    .spacer {
        display: block;
        width: 100%;
        height: 64px;
    }

    .input-wrapper {
        line-height: 16px;
        margin: 0;
        padding-left: 24px;
        width: 176px;
        /* background-color: transparent; */
    }

    #formSearch {
        padding: 6px 18px;
    }

    .input-wrapper input::-webkit-input-placeholder,
    .input-wrapper input::placeholder {
        color: transparent !important;
    }

    .profil-dropdown.show {
        border-radius: 8px;
        background-color: transparent;
    }

    .profil-dropdown .dropdown-menu {
        background-color: transparent;
        padding-top: 0!important;
    }

    .nav-courses-flex {
        flex-direction: column;
    }

    #navbarScrollingDropdown+.dropdown-menu {
        width: 100% !important;
        padding: 20px 0
    }

    .nav-courses-flex-left {
        padding-right: 0;
        margin-right: 0;
    }

    .nav-courses-flex-right {
        padding-right: 0;
    }

    .navbar-collapse {
        position: fixed;
        background: #232323;
        top: 134px;
        left: 0;
        width: 100%;
        padding: 12px 40px;
        z-index: 20;
    }

    .navbar-collapse .nav-link {
        text-transform: uppercase;
    }

    .nav-login,
    .nav-register {
        max-width: 99% !important;
        line-height: 40px !important;
        margin-top: 16px;
        display: none !important;
    }

    .nav-mobile-reg {
        display: block;
    }

    .nav-mobile-reg img {
        width: 28px;
        margin-left: 12px;
    }

    .nav-login {
        margin-right: 0px;
    }

    .navbar-brand img {
        width: 80% !important;
    }

    .navbar-toggler {
        margin-right: 12px;
    }

    .profil-dropdown #navbarScrollingDropdown {
        background-color: transparent;
        color: #fff!important;
        padding-left: 0;
    }
}

@media screen and (max-width: 400px) {
    .navbar-collapse {
        position: fixed;
        background: #232323;
        top: 128px;
        left: 0;
        width: 100%;
        padding: 12px 40px;
        z-index: 20;
    }
}