.detail-header {
    font-family: "TT Hoves";
    font-size: 39px;
    font-weight: 600;
    line-height: 49px;
}

.blogDetail-wrapper img {
    width: 100%;
}
