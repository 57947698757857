.coursesDetail-header {
    font-family: Inter;
    font-size: 60px;
    font-weight: 700;
    line-height: 72px;
    color: #FF4330;
}

.coursesDetail-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #969696;
}

@media screen and (max-width: 600px) {
    .coursesDetail-header {
        font-size: 36px;
        line-height: 40px;
    }
}