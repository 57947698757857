.detail-header {
    font-family: "TT Hoves";
    font-size: 39px;
    font-weight: 600;
    line-height: 49px;
}

.detail-fav-div {
    border-bottom: 1px solid #CACACA;
}

.detail-fav {
    font-family: "TT Hoves";
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #5C5C5C;
}

.detail-title {
    font-family: "TT Hoves";
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}

.detail-text {
    font-family: "TT Hoves";
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
}

.detail-list-span {
    width: 8px;
    height: 8px;
    background-color: #000;
    border-radius: 100%;
    margin-right: 12px;
    margin-bottom: 10px;
}

.detail-instructor-name {
    font-family: "TT Hoves";
    font-size: 29px;
    font-weight: 500;
    line-height: 39px;
}

.detail-badge {
    background-color: #00B2BD;
    padding: 2px 6px;
    border-radius: 2px;
    color: #fff;
    font-family: "TT Hoves";
    font-size: 11px;
    font-weight: 500;
    line-height: 18px;
}

.detail-span {
    font-family: "TT Hoves";
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #505050;
}

.detail-instructor {
    border-bottom: 1px solid #D9D9D9;
}

.detail-right {
    border: 1px solid #C2C2C2;
    padding: 40px 30px 100px;
}

.course-detail-price {
    font-family: "TT Hoves";
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
}

.detail-sale {
    font-family: "TT Hoves";
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: #686868;
}

.detail-old-price {
    text-decoration: line-through;
}

.detail-btn {
    border-radius: 8px!important;
    font-family: "TT Hoves"!important;
    font-size: 18px!important;
    font-weight: 500!important;
    line-height: 28px!important;
    color: #fff!important;
    background-color: #FF4330!important;
    border: none!important;
    width: 90%!important;
    margin-bottom: 8px;
}

.detail-sale-duration {
    font-family: "TT Hoves";
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #A9A9A9;
}

.deatil-right-text {
    font-family: "TT Hoves";
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 6px;
}

.detail-right-instructor {
    font-family: "Arimo";
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    color: #848484;
    margin-bottom: 6px;
}

.detail-right-instructor span {
    color: #161616;
    font-weight: 500;
}

.course-video, .course-coverPhoto {
    height: 438px!important;
    width: 100%!important;
}

.instructorProfilPhoto {
    height: 180px!important;
    width: 180px!important;
}

.instructor-profil-img-right {
    height: 48px!important;
    width: 48px!important;
    border-radius: 100%;
}